
@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 650px;
@attachWidth: 150px;
.eleUse {
	width: 33%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&:nth-child(2) {
		&:before {
			content: "";
			width: 1px;
			border-left: 1px solid #FFF;
			height: 80%;
			top: 10%;
			left: 0;
			position: absolute;
			display: block;
		}

		&:after {
			content: "";
			width: 1px;
			border-right: 1px solid #FFF;
			height: 80%;
			top: 10%;
			right: 0;
			position: absolute;
			display: block;
		}
	}
}

.eleType {
	width: 25%;
	border: 1px solid lighten(@lightTitleBackground, 30%);
	margin: @smallSpace;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80px;
	cursor: pointer;

	&.active {
		filter: drop-shadow(2px 4px 6px black)
	}

	> :first-child {
		margin: @smallSpace 0;
	}
}

.row {
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	margin-bottom: @bigSpace;

	&:last-child {
		flex-grow: 1;
	}
}

.eleTypeUsed {
	display: block;
	width: 100%;

	> div:first-child {
		width: 60%;
		float: left;
		text-align: right;
		padding-right: @smallSpace;
	}

	> div:last-child {
		width: 40%;
		margin-left: 60%;
		text-align: left;
		border-left: 1px solid #EEE;
		padding-left: @smallSpace;
	}
}

.circleList {
	width: 46%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 2%;

	> div:last-child {
		flex-grow: 1;
	}
}
